import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const Error = () => {




    return (

        <Fragment>
            <style jsx={'Screen'}>{` body{ background-image: none; } `}</style>
            <div className='container usuarioNav'>
                <div className='card border-0  shadow mt-5 '>
                    <div className='card-header bg-transparent border-0 mx-auto'>
                        <img src="alert-512.png" className="card-img mt-2" alt="..." />
                    </div>
                    <div className='card-footer bg-white border-0'>
                        <nav className='nav justify-content-center'>
                            <div className="d-flex bd-highlight mb-3">
                                <p className='fs-3 infoLista mx-3 text-center'>Disculpa, tenemos inconvenientes en nuestro sistema. </p>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Error;