import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (url, { rejectWithValue }) => {
    try {
      const response = await api.get(url);
      return ({
        data : response.data,
        filter : url.includes('search') ? true : false,
      });
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    } 
  }
);
