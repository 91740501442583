import React, { Fragment, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// batch
import { setError } from "redux/slices/user/userSlice";
import { fetchLogin } from "redux/slices/user/userAsyncThunk";
import { reset } from "redux/slices/products/productsSlice";
import "./_Login.scss";
const INITAL_VALUES = {
  username: "",
  password: "",
};

const validator = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "El usuario es obligatorio";
  } else if (values.username.includes("@")) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
      errors.username = "Email invalido";
    }
  }

  if (!values.password) {
    errors.password = "La contraseña es obligatoria";
  }
  // else if (values.password.length < 6) {
  //   errors.password = "La contraseña debe tener al menos 6 caracteres";
  // }

  return errors;
};

let setSubmittingHiger = () => null;

const Login = () => {
  const dispatch = useDispatch();
  const { error, isLoading, name } = useSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    setSubmittingHiger(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (name) {
      dispatch(reset());
      navigate("/listado", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, navigate]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmittingHiger = setSubmitting;
    dispatch(setError(null));

    setTimeout(() => {
      dispatch(
        fetchLogin({ email: values.username, password: values.password })
      );
    }, 2000);
  };

  return (
    <div className="wrapper">
      <Fragment>
        <div className="row mt-5">
          <div className="text-center mt-5 mb-5">
            <img src="/assets/logo.png" width="110px" height="110px" alt="" />
            <p className="text-white display-6 fs-3">0810-220-7597(SJYS)</p>
          </div>
        </div>

        <div className="row subfondo ">
          <Formik
            initialValues={INITAL_VALUES}
            validate={validator}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="w-75 mx-auto mt-5 sidebarFont">
                <div className="form-group">
                  <i className="bi bi-person fs-4 text-light"></i>
                  <label
                    htmlFor="username"
                    className="text-white fw-light  mx-3"
                  >
                    USUARIO
                  </label>
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="py-0 mx-auto text-center text-white badge bg-danger"
                  />
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    className="form-control rounded-0 mt-1  px-5 text-white mb-4 bg-transparent border-0 border-bottom"
                    // autoComplete="username"
                    autoComplete="off"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="form-group">
                  <i className="bi bi-lock fs-4 text-light mx-2"></i>
                  <label
                    htmlFor="password"
                    className="text-white fw-light mx-3"
                  >
                    CONTRASEÑA
                  </label>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="py-0 mx-auto text-center text-white badge bg-danger"
                  />
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    className="form-control rounded-0 mt-1 px-5 text-white mb-4 bg-transparent border-0 border-bottom"
                    autoComplete="current-password"
                    disabled={isSubmitting}
                  />
                </div>

                {error && <div className="error mb-2">{error}</div>}

                {isSubmitting ? (
                  <button
                    className="form-control bg-transparent mt-3 btn text-white border rounded-pill py-3 hover-ingresar"
                    disabled={isSubmitting}
                  >
                    <span
                      className="spinner-border spinner-border-sm mx-1"
                      role="status"
                      aria-hidden="true"
                    />
                    Iniciando sesión...
                  </button>
                ) : (
                  <button
                    className="form-control bg-transparent mt-3 btn text-white border rounded-pill py-3 hover-ingresar"
                    type="submit"
                  >
                    Iniciar sesión
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Fragment>
    </div>
  );
};

export default Login;
