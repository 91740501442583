import React, {Fragment} from 'react';

const Movimiento = ({fecha, monto, descripcion, tipoComprobante, numeroComprobante }) => {
    return ( 
        <Fragment>

                <div className='border-0 border-bottom '>
                    <p className='mx-3 mt-3'>{fecha}</p>
                    <ul className='mx-3 nav justify-content-between'>
                            <li className='fs-5'>{tipoComprobante} / {numeroComprobante}</li>

                            <li className='fs-5 space'>{monto}</li>
                    </ul>
                    <p className='mx-3 mb-3'>{descripcion}</p>
                </div>

        </Fragment>
     );
}
 
export default Movimiento;