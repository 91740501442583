import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "redux/slices/products/productsAsyncThunk";
import { initData } from "redux/slices/products/productsSlice";

const AutoComplete = () => {
  const dispatch = useDispatch();
  const productsSearchState = useSelector((state) => state.productsSearch);
  const [suggestions] = useState(productsSearchState.medidas);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const SuggestionsListComponent = () => {
    let suggestionsListComponent = [input, ...filteredSuggestions];
    return suggestionsListComponent.length ? (
      <ul class="suggestions">
        {suggestionsListComponent.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          return (
            <li className={className} key={index} onClick={onClick}>
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : null;
  };

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked.slice(0, 3));
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };
  const onClick = (e) => {
    setFilteredSuggestions([]);
    dispatch(initData());
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    dispatch(
      fetchProducts("articulos?paginate=3&search=" + e.target.innerText)
    );
  };
  return (
    <>
      <input
        className=" text-white bg-transparent border-0 border-bottom mx-4"
        type="text"
        onChange={onChange}
        value={input}
        placeholder="185/60R14"
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};
export default AutoComplete;
