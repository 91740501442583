import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

export const fetchLogin = createAsyncThunk(
  "user/fetchLogin",
  async (body, { rejectWithValue }) => {
    try {
      const response = await api.post("/login", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const fetchLogout = createAsyncThunk(
  "user/fetchLogout",
  async (body, { rejectWithValue }) => {
    try {
      const response = await api.get("/logout", body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

