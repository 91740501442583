import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Image from "../components/Image";
import Whatsapp from "components/Whatsapp";
import TrafficLight from "components/TrafficLight";

const ProductoElegido = () => {
  const location = useLocation();
  const { state } = location;
  const numberFormat2 = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });

  return (
    <Fragment>
      {state ? (
        <Fragment>
          <style jsx={"Screen"}>{`
            body {
              background-image: none;
            }
          `}</style>

          <div className="container usuarioNav">
            <div className="card border-0  shadow mt-5 ">
              <div className="card-header bg-transparent border-0 mx-auto">
                <Image
                  url={state.image_detail}
                  urlDefault={state.image_default}
                />
              </div>

              <div className="card-footer bg-white border-0">
                <p className="card-title text-center fw-bold">
                  {state?.design}
                </p>
                <p className="card-text text-center text-secondary">
                  {state?.medida}
                </p>
                <p className="card-text text-center text-secondary">
                  {state?.descripcion}
                </p>

                <nav className="nav justify-content-center">
                  <p className="fs-3 infoLista mx-3 ">
                    {numberFormat2.format(parseFloat(state?.precio).toFixed(2))}
                  </p>
                  <Whatsapp />
                </nav>
                <TrafficLight
                  stock={state.stock}
                  classNames={[
                    "d-flex align-items-center justify-content-center bd-highlight ",
                    "pb-1",
                    "card-footer m-0 py-1 text-center  bg-transparent border-0 rounded",
                    "btn rounded-circle p-2 noShadow mb-2",
                  ]}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default ProductoElegido;
