import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchProducts } from "redux/slices/products/productsAsyncThunk";
import { initData } from "redux/slices/products/productsSlice";
import { fetchLogout } from "redux/slices/user/userAsyncThunk";
import AutoComplete from "./Autocomplete";
import "./styles.css";

const Header = ({ titulo }) => {
  const user = useSelector((state) => state.user);
  const [showInputSearch, setShowInputSearch] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Fragment>
      <nav className="navbar navbar-expand-sm navbar-light bg-navbar navbar-dark py-3 sidebarFont">
        <button
          className="btn btn-primary focus-none bg-transparent border-0 mx-2 fs-3 mostrarSidebar"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBackdrop"
          aria-controls="offcanvasWithBackdrop"
        >
          ☰
        </button>

        <ul className="nav navbarMenu">
          <li className="border-0 d-flex align-items-center mx-3 ">
            <i className="bi bi-house text-white fs-3 "></i>
            <Link
              to="/listado"
              className="text-white text-decoration-none mx-2"
            >
              Home
            </Link>
          </li>

          <li className="border-0 d-flex align-items-center mx-3">
            <i className="bi bi-safe2 text-white fs-3 "></i>
            <Link
              to="/listado"
              className="text-white text-decoration-none mx-2"
            >
              Productos
            </Link>
          </li>
          <li className="border-0 d-flex align-items-center mx-3">
            <i className="bi bi-credit-card text-white fs-3"></i>
            <Link to="/cuenta" className="text-white text-decoration-none mx-2">
              Cuenta Corriente
            </Link>
          </li>
        </ul>

        <p className="fs-3 text-white nav tituloHeader sidebarFont">{titulo}</p>
        {location.pathname === "/listado" ? (
          <div className="navbarMenu">
            {showInputSearch ? <AutoComplete /> : null}
            <button
              className="bg-transparent border-0"
              onClick={() => {
                setShowInputSearch(!showInputSearch);
                if (showInputSearch) {
                  dispatch(initData());
                  dispatch(fetchProducts("articulos?paginate=3"));
                }
              }}
            >
              {showInputSearch ? (
                <i className="bi bi-x fs-4 text-white"></i>
              ) : (
                <i className="bi bi-search  fs-5 text-white"></i>
              )}
            </button>
          </div>
        ) : null}
        <ul className="nav navbarMenu align-items-center">
          <li className="border-0 d-flex align-items-center mx-3">
            <i className="bi bi-person text-white fs-3"></i>
            <span className="text-white text-decoration-none mx-2">
              {user?.name}
            </span>
          </li>
          <li className="border-0 d-flex align-items-center mx-3">
            <i className="bi bi-power text-white fs-3"></i>
            <Link
              to="/login"
              className="text-white text-decoration-none mx-2"
              onClick={() => dispatch(fetchLogout())}
            >
              Cerrar Sesión
            </Link>
          </li>
        </ul>

        {location.pathname === "/listado" ? (
          <div className="mostrarSidebar">
            {showInputSearch ? <AutoComplete /> : null}
            <button
              className="bg-transparent border-0"
              onClick={() => {
                setShowInputSearch(!showInputSearch);
                if (showInputSearch) {
                  dispatch(fetchProducts("articulos?paginate=3"));
                }
              }}
            >
              {showInputSearch ? (
                <i className="bi bi-x   fs-3 text-white"></i>
              ) : (
                <i className="bi bi-search  fs-3 text-white"></i>
              )}
            </button>
          </div>
        ) : null}
      </nav>

      <div
        className="offcanvas offcanvas-start bg-sidebar shadow-lg"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
      >
        <div className="text-end">
          <button
            type="button"
            className="btn-close focus-none p-3 btn-close-white text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-header  d-flex justify-content-between">
          <div className="mx-auto pb-5 pt-4 pb-4">
            <h4
              className="offcanvas-title text-white border-bottom tituloNav fs-3 "
              id="offcanvasWithBackdropLabel"
            >
              San Juan y Solís
            </h4>
            <h5
              className="offcanvas-title text-white text-center mt-2 usuarioNav"
              id="offcanvasWithBackdropLabel"
            >
              {user?.name}
            </h5>
          </div>
        </div>

        <div className="offcanvas-body p-0 sidebarFont">
          <ul className="list-unstyled">
            <li className="border-0 border-bottom border-top border-secondary py-3 d-flex align-items-center">
              <i className="bi bi-house text-white fs-3 mx-3"></i>
              <a
                href="/listado"
                className="text-white text-decoration-none mx-2"
              >
                Home
              </a>
            </li>
            <li className="border-0 border-bottom py-3 border-secondary d-flex align-items-center">
              <i className="bi bi-safe2 text-white fs-3 mx-3 "></i>
              <a
                href="/listado"
                className="text-white text-decoration-none mx-2"
              >
                Productos
              </a>
            </li>
            <li className="border-0 border-bottom py-3 border-secondary d-flex align-items-center">
              <i className="bi bi-cart2 text-white fs-3 mx-3"></i>
              <a
                href="/cuenta"
                className="text-white text-decoration-none mx-2"
              >
                Cuenta Corriente
              </a>
            </li>
            <li className="border-0 border-bottom py-3 border-secondary d-flex align-items-center">
              <i className="bi bi-door-open text-white fs-3 mx-3"></i>
              <a
                href="#!"
                className="text-white text-decoration-none mx-2"
                onClick={() => {
                  dispatch(fetchLogout());
                  navigate("/login");
                }}
              >
                Cerrar Sesión
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
