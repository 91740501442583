import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

export function RequireAuth({ children }) {
  let location = useLocation();
  const { token } = useSelector((state) => state.user);
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
