import React from "react";

const Image = ({ url, urlDefault }) => {
  return (
    <img
      src={url}
      className="card-img mt-2"
      alt="..."
      onError={({ currentTarget }) => {
        if (currentTarget.src !== urlDefault) {
          // if urlDefault is not set in server looping happen anyway
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = urlDefault;
        }
      }}
    />
  );
};

export default Image;
