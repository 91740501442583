import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

export const fetchBranches = createAsyncThunk(
  "branches/fetchBranches",
  async () => {
    try {
      const response = await api.get("/sucursal");
      return response.data;
    } catch (error) {}
  }
);
