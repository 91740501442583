import React, { Fragment } from "react";
import Image from "components/Image";
import Whatsapp from "components/Whatsapp";
import TrafficLight from "components/TrafficLight";

const Producto = ({
  stock,
  titulo,
  descripcion,
  medida,
  precio,
  imagenLista,
  onClick,
  imagenDefault,
}) => {
  const numberFormat2 = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });

  return (
    <Fragment>
      <div
        className="card tamano flex-row shadow border-0 rounded mx-auto mb-3 usuarioNav"
        onClick={onClick}
      >
        <Image url={imagenLista} urlDefault={imagenDefault} />
        <div className="card-body p-0 mt-5">
          <p className="card-title infoCard fs-5">{titulo}</p>
          <p className="infoCard text-secondary">{medida}</p>
          <p className="infoCard text-secondary">{descripcion}</p>
          <nav className="nav justify-content-start">
            <p className="fs-3 infoLista mx-0 ">
              {numberFormat2.format(parseFloat(precio).toFixed(2))}
            </p>
            <Whatsapp />
          </nav>
          <TrafficLight
            stock={stock}
            classNames={[
              "d-flex align-items-center flex-nowrap bd-highlight",
              "pb-1 ml-1",
              "card-footer  py-1 text-center bg-transparent border-0 rounded  ",
              "btn rounded-circle p-2 noShadow mb-2",
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Producto;
