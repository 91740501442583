import { createSlice } from "@reduxjs/toolkit";
import { fetchBranches } from "redux/slices/branches/branchesAsyncThunk";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  branches: [],
};

export const branchesSlice = createSlice({
  name: "branches",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      return {
        isLoading: false,
        error: null,
        branches: action.payload.data,
      };
    });

    builder.addCase(fetchBranches.rejected, (state, action) => {
      return {
        isLoading: false,
        error: action.payload,
        branches: [],
      };
    });
  },
});

export default branchesSlice.reducer;
