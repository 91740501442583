import {createSlice} from '@reduxjs/toolkit';
import { fetchProductsSearch } from './productsSearchAsyncThunk';

const INITIAL_STATE = {
    isLoading: false,
    error: null,
    medidas: [],

}

export const productsSearchSlice = createSlice({
    name: 'productsSearch',
    initialState: INITIAL_STATE,
    reducers: {
        reset: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductsSearch.pending, (state) => {
            state.isLoading = true;
        }
        );
        builder.addCase(fetchProductsSearch.fulfilled, (state, action) => {
            state.isLoading = false;
            state.medidas = action.payload;

        }
        );
        builder.addCase(fetchProductsSearch.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
                
        }
        );
    },
}
);
export const { reset } = productsSearchSlice.actions
export default productsSearchSlice.reducer;