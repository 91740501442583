import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const Whatsapp = () => {
  const sucursalName = useSelector(
    (state) => state.user?.user_stock?.sucursal.descripcion
  );
  const getLink = useMemo(() => {
    const message = "?text=Hola%20estoy%20interesado%20en%20un%20articulo";
    switch (sucursalName) {
      case "CASA CENTRAL":
        return `https://wa.me/5491168502748${message}`;
      case "ROSARIO":
        return `https://wa.me/5493417029151${message}`;
      case "CORDOBA":
        return `https://wa.me/5493513552204${message}`;
      case "MENDOZA":
        return `https://wa.me/5492613404805${message}`;
      case "POSADAS":
        return `https://wa.me/5493764101622${message}`;
      case "BUENOS AIRES SUR":
      case "BUENOS AIRES NORTE":
        return `https://wa.me/5491568502748${message}`;
      default:
        return null;
    }
  }, [sucursalName]);
  return (
    <div className="d-flex bd-highlight mb-3">
      <a
        type="button"
        className="mx-4 border-0 mb-2 bg-transparent bd-highlight"
        href={getLink}
        target="_blank"
        rel="noreferrer"
      >
        <i className="bi bi-whatsapp fs-3 text-success"></i>
      </a>
    </div>
  );
};

export default Whatsapp;
