import React, { useMemo } from "react";

import "./trafficLight.scss";

const TrafficLight = ({ classNames, stock }) => {
  const status = useMemo(() => {
    if (stock === 0) {
      return "btn-danger";
    } else if (stock >= 1 && stock <= 8) {
      return "btn-warning";
    } else if (stock >= 9) {
      return "btn-success";
    }
    return "btn-success";
  }, [stock]);

  const textStatus = useMemo(() => {
    if (stock === 0) {
      return "no hay stock";
    } else if (stock >= 1 && stock <= 8) {
      return "consultar";
    } else if (stock >= 9) {
      return "hay stock";
    }
    return "btn-success";
  }, [stock]);

  return (
    <div className={`${classNames[0]}`}>
      <label className={`${classNames[1]}`}>Cantidad</label>
      <div className={`${classNames[2]}`}>
        <div className="trafficLightWrapper">
          <div className="trafficLight">
            <div className={`${status} ${classNames[3]} mr-1`} />
          </div>
          <span> &nbsp;{textStatus}</span>
        </div>
      </div>
    </div>
  );
};

export default TrafficLight;
