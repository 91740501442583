import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProducts } from "redux/slices/products/productsAsyncThunk";
import Producto from "../components/Listado/Producto";
import InfiniteScroll from "react-infinite-scroller";
import { fetchProductsSearch } from "redux/slices/productsSearch/productsSearchAsyncThunk";

const Lista = () => {
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.products);
  const navigate = useNavigate();

  const loadFunc = () => {
    if (!productsState.isLoading) {
      dispatch(fetchProducts(productsState.nextUrl));
    }
  };

  useEffect(() => {
    dispatch(fetchProductsSearch());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productsState.error) {
      navigate("/error");
    }
  }, [productsState.error, navigate]);

  return (
    <InfiniteScroll
      loadMore={loadFunc}
      hasMore={!!productsState.nextUrl}
      useWindow={true}
      loader={
        <div className="bg-secondary text-light" key={0}>
          Loading ...
        </div>
      }
    >
      <Fragment>
        <style jsx={"Screen"}>{`
          body,
          html {
            background-image: none;
          }
        `}</style>

        <div className="container mt-4">
          {productsState.products?.map(
            (
              product //map para recorrer el array
            ) => (
              <Producto
                key={product.id}
                stock={product.stock}
                titulo={product.design}
                descripcion={product.descripcion}
                precio={product.precio}
                medida={product.medida}
                imagenLista={product.image_list}
                imagenDefault={product.image_default}
                onClick={() => {
                  navigate(`/producto/`, { state: product });
                }}
              />
            )
          )}
        </div>
      </Fragment>
      {/*  */}
    </InfiniteScroll>
  );
};

export default Lista;
