import { createSlice } from "@reduxjs/toolkit";
import { fetchProducts } from "./productsAsyncThunk";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  products: [],
  nextUrl: "/articulos?paginate=3",
};

export const productsSlice = createSlice({
  name: "products",
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    initData: (state) => {
      state.products = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = [...state.products, ...action.payload.data.data];
      state.nextUrl = action.payload.data.links.next ?? null;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.products = [];
      state.nextUrl = null;
    });
  },
});
export const { reset, initData } = productsSlice.actions;
export default productsSlice.reducer;
