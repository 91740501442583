import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Outlet , Navigate} from "react-router-dom";
import Header from "components/Header";
import Lista from "./pages/Lista";
import Login2 from "./pages/Login2";
import ProductoElegido from "pages/ProductoElegido";
import CuentaCorriente from "pages/CuentaCorriente";
import ListadoAMB from "pages/ListadoABM";
import { RequireAuth } from "components/RequireAuth";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./scss/_lineicons.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Error from "pages/error";

const container = document.getElementById("root");
const root = createRoot(container);

const HeaderLayout = () => (
  <>
    <Header />
    <Outlet />
  </>
);

root.render(

    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/login" element={<Login2 />} />
          <Route
        path="*"
        element={<Navigate to="/listado" replace />}
            />
          <Route element={<HeaderLayout />}>
            <Route path="/producto" element={
               <RequireAuth>
                 <ProductoElegido />
               </RequireAuth>
            } />
            <Route path="/cuenta" element={
               <RequireAuth>
                 <CuentaCorriente />
               </RequireAuth>
          } />
            <Route path="/abm" element={<ListadoAMB />} />
            <Route
              path="/listado"
              element={
                <RequireAuth>
                  <Lista load={false} />
                </RequireAuth>
              }
            />
            <Route
              path="/error"
              element={
                <RequireAuth>
                  <Error load={false} />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </Provider>
    </BrowserRouter>

);
