import {createSlice} from '@reduxjs/toolkit';
import { fetchCurrentAccountSaldo } from './currentAccountAsyncThunk';



const INITIAL_STATE = {
    isLoading: false,
    error: null,
    saldo: 0,
}

export const currentAccountSaldoSlice = createSlice({
    name: 'currentAccountSaldo',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentAccountSaldo.pending, (state) => {
            state.isLoading = true;
        }
        );
        builder.addCase(fetchCurrentAccountSaldo.fulfilled, (state, action) => {
      
         
                state.isLoading= false;
                state.error= null;
                state.saldo= action.payload.data[0].saldo;
          
        }
        );
        builder.addCase(fetchCurrentAccountSaldo.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error;
            state.saldo= 0;
        }
        );
    },
}
);

export default currentAccountSaldoSlice.reducer;