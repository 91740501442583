import axios from "axios";


axios.defaults.headers.common["Accept"] = "application/json";
const axiosIntance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

axiosIntance.interceptors.request.use((config) => {
  if (!config.headers.Authorization && localStorage.getItem("firestone")) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "firestone"
    )}`;
  }
  return config;
});

axiosIntance.interceptors.response.use((config) => {
   return config;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("firestone");
    localStorage.removeItem("user");
    
    window.location.reload();
    
  }
  return Promise.reject(error);
}
);


export default axiosIntance;
