import { configureStore } from "@reduxjs/toolkit";
import useReducer from "redux/slices/user/userSlice";
import branchReducer from "redux/slices/branches/branchesSlice";
import productsReducer from "redux/slices/products/productsSlice";
import productsSearchReducer from "redux/slices/productsSearch/productsSearchSlice";
import currentAccountSaldoReducer from "redux/slices/currentAccount/currentAccountSaldoSlice";
import currentAccountTransReducer from "./slices/currentAccount/currentAccountTransSlice";


const reducer = {
  user: useReducer,
  branch: branchReducer,
  products: productsReducer,
  productsSearch : productsSearchReducer,
  currentAccountSaldo : currentAccountSaldoReducer,
  currentAccountTrans : currentAccountTransReducer,

};

export const store = configureStore({ reducer });
