import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

export const fetchProductsSearch = createAsyncThunk(
  "productsSearch/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/articulos/medida');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    } 
  }
);
