import React, {Fragment} from 'react';


const ConsultaABM = ({usuario,vendedor,cliente}) => {
    return ( 
        <Fragment>

        <div className='border-0 border-bottom '>
            <p className='mx-3 mt-3 mb-2'>{vendedor}</p>

            <div className="d-flex bd-highlight mb-2">
                <div className="px-2 mx-2 fs-5 flex-grow-1 bd-highlight">{usuario}</div>
                <button 
                    type="button" 
                    className='bi bi-pencil-square border-0 btn btn-editar text-black p-1 px-2 bd-highlight '
                    data-bs-toggle="modal" 
                    data-bs-target="#editarModal">
                </button>
                <button 
                    type="button" 
                    className="bi bi-trash border-0 btn btn-eliminar p-1 bd-highlight mx-3 px-2" 
                    data-bs-toggle="modal" 
                    data-bs-target="#eliminarModal">
                </button>
            </div>

            <ul className='mx-3 mb-3 nav justify-content-start'>
                    <li>{cliente}</li>
            </ul>
        </div>

        {/* Modal para Eliminar un registro */}

        <div className="modal fade" id="eliminarModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered sidebarFont">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title fw-bold text-center mx-auto mx-5" id="staticBackdropLabel">¿Desea eliminar el registro?</h5>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger fs-5 form-control">Eliminar</button>
                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
        </div>

        {/* Modal para Editar un registro */}

        <div className="modal fade" id="editarModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered sidebarFont">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title fw-bold text-center mx-auto mx-5" id="staticBackdropLabel">Editar Registro</h5>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div className="modal-body">
                    <label className='fs-5'>Usuario</label>
                    <input
                        type='text'
                        className='form-control'
                    />
                    <label className='mt-3 fs-5'>Vendedor</label>
                    <input
                        type='text'
                        className='form-control'
                    />
                    <label className='mt-3 fs-5'>Cliente</label>
                    <input
                        type='text'
                        className='form-control mb-3'
                    />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary fs-5 form-control">Guardar Cambios</button>
                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
            </div>

        </Fragment>
     );
}
 
export default ConsultaABM;