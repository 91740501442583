import {
    createSlice
} from '@reduxjs/toolkit';
import {
    fetchCurrentAccountTransactions
} from './currentAccountAsyncThunk';



const INITIAL_STATE = {
    isLoading: false,
    error: null,
    transactions: [],
}

export const currentAccountTransactionsSlice = createSlice({
    name: 'currentAccountTransactions',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentAccountTransactions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCurrentAccountTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = null
            state.transactions = action.payload.data
        });
        builder.addCase(fetchCurrentAccountTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error;
            state.transactions = []
        });
    },
});

export default currentAccountTransactionsSlice.reducer;