import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

export const fetchCurrentAccountSaldo = createAsyncThunk(
  "currentAccount/fetchCurrentAccountSaldo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/cuentacorriente/saldo");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    } 
  }
);

export const fetchCurrentAccountTransactions = createAsyncThunk(
  "currentAccount/fetchCurrentAccountTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/cuentacorriente/transacciones");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    } 
  }
);
