import React, {Fragment} from 'react';
import ConsultaABM from 'components/ConsultaABM';

const ListadoAMB = () => {
    return ( 
        <Fragment>
            <style jsx={'Screen'}>{` body{ background-image: none; } `}</style>

            <div className='container mt-5 mb-5 sidebarFont  shadow rounded py-5'>
                    <h1 className='lead fw-bold mx-2'>Listado de Registros</h1>

                <ul className='nav justify-content-end border-bottom pb-4'>
                    <button 
                    type="button" 
                    className="fs-5 btn fw-bold mt-3 mb-3 px-3 mx-2 border-0 bg-transpsarent btn-nuevo text-black rounded" 
                    data-bs-toggle="modal" 
                    data-bs-target="#agregarModal">
                        Nuevo
                    </button>
                </ul>

                {/* Iterar con un map */}
                <ConsultaABM
                    usuario='Julio'
                    vendedor='Maduro'
                    cliente='Ramirez'
                />
                <ConsultaABM 
                    usuario='Fabiana'
                    vendedor='Pedro'
                    cliente='Luis'
                />
                 <ConsultaABM 
                    usuario='Andrea'
                    vendedor='Carmen'
                    cliente='Diego'
                />

            </div>

            {/* Modal para añadir un registro */}

            <div className="modal fade" id="agregarModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered sidebarFont">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title fw-bold text-center mx-auto mx-5" id="staticBackdropLabel">Añadir un Registro</h5>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                </div>
                <div className="modal-body">
                    <label className='fs-5'>Usuario</label>
                    <input
                        type='text'
                        className='form-control'
                    />
                    <label className='mt-3 fs-5'>Vendedor</label>
                    <input
                        type='text'
                        className='form-control'
                    />
                    <label className='mt-3 fs-5'>Cliente</label>
                    <input
                        type='text'
                        className='form-control mb-3'
                    />
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary fs-5 form-control">Añadir</button>
                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Cerrar</button>
                </div>
                </div>
            </div>
            </div>



        </Fragment>
     );
}
 
export default ListadoAMB;