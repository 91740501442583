import { createSlice } from "@reduxjs/toolkit";
import { fetchLogin, fetchLogout } from "redux/slices/user/userAsyncThunk";

const INITIAL_STATE = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
      name: "",
      access_token: "",
      expires_at: "",
      isLoading: false,
      error: null,
    };

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setError(state, { payload }) {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      const response = {
        ...action.payload.data.user,
        isLoading: false,
        error: null,
        token: action.payload.data.token,
      };
      localStorage.setItem("user", JSON.stringify(response));
      localStorage.setItem("firestone",action.payload.data.token);
      return response;
    });

    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchLogout.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchLogout.fulfilled, (state, action) => {
      const response = {
        ...action.payload.data.user,
        isLoading: false,
        error: null,
        token: null,
      };
      localStorage.removeItem("user");
      localStorage.removeItem("firestone");
      return response;
    });

    builder.addCase(fetchLogout.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setError } = userSlice.actions;
export default userSlice.reducer;
