import React, { Fragment, useEffect } from "react";
import Movimiento from "components/Movimiento";
import {
  fetchCurrentAccountSaldo,
  fetchCurrentAccountTransactions,
} from "redux/slices/currentAccount/currentAccountAsyncThunk";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const CuentaCorriente = () => {
  const dispatch = useDispatch();
  const currentAccountState = useSelector((state) => state.currentAccountSaldo);
  const currentAccountTransState = useSelector(
    (state) => state.currentAccountTrans
  );
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchCurrentAccountSaldo());
    dispatch(fetchCurrentAccountTransactions());
  }, [dispatch]);

  const labelComrobante = (comprobante) => {
    switch (comprobante) {
      case "FAC":
        return "Factura";
      case "N/C":
        return "Nota de crédito";
      case "REC":
        return "Recibo";
      default:
        return comprobante;
    }
  };
  const labelFecha = (fecha) => {
    return dayjs(fecha).locale("es").format("D MMMM YYYY");
  };
  const numberFormat2 = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });

  useEffect(() => {
    if (currentAccountState.error) {
      navigate("/error");
    }
  }
  , [currentAccountState.error, navigate]);

  return (
    <Fragment>
      <style jsx={"Screen"}>{`
        body {
          background-image: none;
        }
      `}</style>

      <div className="container mt-5 mb-5 sidebarFont shadow rounded py-5">
        <h1 className="lead fw-bold mx-2">Cuenta Corriente</h1>

        <ul className="nav justify-content-end">
          <div className="card-header bg-transparent border-0">
            <li className="mx-5 fs-5">Saldo</li>
          </div>
        </ul>

        <ul className="nav justify-content-end border-bottom pb-4">
          <div className="card">
            <li className="fs-3 fw-bold mx-5">
              <span className="fw-normal"></span>
              {numberFormat2.format(
                parseFloat(currentAccountState?.saldo).toFixed(2)
              )}
            </li>
          </div>
        </ul>

        <h1 className="lead fw-bold mx-2 mb-4 mt-3">Movimientos</h1>
        {currentAccountTransState?.transactions?.map(
          (
            transaccion //map para recorrer el array
          ) => (
            <Movimiento
              key={transaccion.id}
              fecha={
                transaccion.fecha
                  ? labelFecha(transaccion.fecha)
                  : transaccion.fecha_apl
                  ? labelFecha(transaccion.fecha_apl)
                  : ""
              }
              monto={numberFormat2.format(
                parseFloat(transaccion.importe).toFixed(2)
              )}
              tipoComprobante={
                transaccion.tipo_comp
                  ? labelComrobante(transaccion.tipo_comp)
                  : transaccion.tipo_comprobante_apl
                  ? labelComrobante(transaccion.tipo_comprobante_apl)
                  : "Sin comprobante"
              }
              numeroComprobante={
                transaccion.numero_comp
                  ? transaccion.numero_comp
                  : ""  
              }
            />
          )
        )}

        {/* <Movimiento
                    fecha='10 de Noviembre'
                    producto='Caucho 4X4'
                    precio={720}
                    descripcion='Está bien fino el caucho!'
                />

                <Movimiento
                    fecha='27 de Octubre'
                    producto='Un Tetero'
                    precio={1000}
                    descripcion='Tremendo tetero para bebes!'
                /> */}
      </div>
    </Fragment>
  );
};

export default CuentaCorriente;
